@font-face {
  font-family: "LatoBold";
  src: url("../public/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../public/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SofiaProMedium";
  src: url("../public/fonts/Sofia-Pro-Medium.ttf") format("truetype");
}

body {
  margin: 0;
}
@keyframes floating-heart {
  0% {
    opacity: 0;
    bottom: 0%;
    /* right: 0%; */
    transform: rotate(5deg);
  }
  10% {
    opacity: 1;
    bottom: 20%;
    /* right: 70%; */
    transform: rotate(-5deg);
  }
  20% {
    bottom: 40%;
    /* right: 20%; */
    transform: rotate(5deg);
  }
  30% {
    bottom: 60%;
    /* right: 60%; */
    transform: rotate(-5deg);
  }
  40% {
    opacity: 1;
    bottom: 80%;
    /* right: 30%; */
    transform: rotate(5deg);
  }
  48% {
    opacity: 0;
    bottom: 100%;
    /* right: 50%; */
    transform: rotate(-5deg);
  }
  100% {
    opacity: 0;
    bottom: 100%;
    /* right: 80%; */
    display: none;
    transform: rotate(5deg);
  }
}